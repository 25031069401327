
import '../styles/App.css';
import Slider from './Slider'
import React, { useState } from 'react';
import disallowedDomains from './DisallowedDomains';
import {authServerUrl} from '../resources/utils';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';

const isValidCompanyEmail = (email) => {
  const emailParts = email.split('@');
  if (emailParts.length === 2) {
    const domain = emailParts[1].toLowerCase();
    return !disallowedDomains.includes(domain);
  }
  return false;
};

function generateRandomToken(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    token += characters.charAt(randomIndex);
  }
  return token;
}

const Signup = () => {

  const [confirmationMessageVisible, setConfirmationMessageVisible] = useState(false);

  const handleSignUp = (values) => {
    values.validationToken = generateRandomToken(10);
    // Send the user registration data to the server
    fetch(`${authServerUrl}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values), // Values contain the user registration data
    })
      .then((response) => {
        if (response.ok) {
          // Registration was successful, now trigger the email sending process
          sendConfirmationEmail(values);
        } else {
          throw new Error('Registration failed');
        }
      })
      .then((data) => {
        // Handle the response from the server (e.g., show confirmation message)
        console.log('Response Body:', data);
        setConfirmationMessageVisible(true);
      })
      .catch((error) => {
        // Handle errors (e.g., show an error message)
        console.error(error);
      });
  };
  
  const sendConfirmationEmail = (values) => {
    // Make an API request to your server to send the confirmation email
    fetch(`${authServerUrl}/everify`, {
      
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values), // Send user information to the server for the email
    })
      .then((response) => {
        if (response.ok) {
          // Email sent successfully
        } else {
          throw new Error('Email sending failed');
        }
      })
      .catch((error) => {
        // Handle errors (e.g., show an error message)
        console.error(error);
      });
  };

  const validateEmail = (rule, value) => {
    if (isValidCompanyEmail(value)) {
      return Promise.resolve(); // Valid email, resolve the promise
    } else {
      return Promise.reject('Please use a valid company email address.'); // Invalid email, reject the promise
    }
  };

  return (
    <div className="sign-in-container">
        <Slider />
      <div className="signin">
        <div className="center-content">
          <img src="./xplorer.png" alt="Logo" style={{ maxWidth: '200px', maxHeight: '100px', height: 'auto', width: 'auto', marginBottom: '0px'}} />
          <p style={{ fontSize: '15px', color: '#0b2241', marginTop: '0px', marginBottom: '30px' }}>Empower Your Search with AI</p>
          <Form name="signup" onFinish={handleSignUp}>
            <Form.Item 
              name="email" 
              required
              rules={[
                { 
                  required: true, message: 'Enter a valid company email',
                  validator: validateEmail },
              ]}
              disabled={confirmationMessageVisible}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item name="firstName" rules={[{ required: true, message: 'Required'}]} disabled={confirmationMessageVisible}>
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="lastName" rules={[{ required: true, message: 'Required'}]} disabled={confirmationMessageVisible}>
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="company" rules={[{ required: true, message: 'Required'}]} disabled={confirmationMessageVisible}>
              <Input placeholder="Company/Institution" />
            </Form.Item>
            <Form.Item name="password" rules={[{required: true, message: 'Enter a valid password'},{min: 8, message: 'Password must be at least 8 characters' }]} disabled={confirmationMessageVisible}>
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item name="reenterPassword" rules={[
                { required: true, message: 'Reenter your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Passwords do not match');
                  },
                }),
              ]} disabled={confirmationMessageVisible}>
              <Input.Password placeholder="Reenter Password" />
            </Form.Item>
            <Form.Item name="privacyPolicy" valuePropName="checked" rules={[{ required: true, message: 'You must read and agree to the Privacy Policy' }]} style={{ marginBottom: '-10px' }}>
              <div style={{ display: 'flex', alignItems: 'left'  }}>
                <Checkbox />
                <span style={{ fontSize: '12px', marginLeft: '5px'}}>
                  I have read the <a href="https://www.38labs.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </span>
              </div>
            </Form.Item>
            <Form.Item name="marketingCommunication" valuePropName="checked" >
              <div style={{ display: 'flex', alignItems: 'left' }}>
                <Checkbox />
                <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                I agree to receive marketing information
                </span>
              </div>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ backgroundColor: '#0b2241', color: 'white' }}>
                Start Free Trial
              </Button>
            </Form.Item>
            {confirmationMessageVisible && (
          <div className="check-email-message" style={{paddingBottom: '10px'}}>
            <p style={{ color: '#22b14cd9', fontSize: '16px', textAlign: 'center' }}>
            Check your inbox & spam folders for a confirmation link from xplorer@38labs.tech 
            </p>
          </div>
          )}
          </Form>

          <div className="forgot-password-message" style={{paddingBottom: '50px'}}>
            <a href="/login" style={{ color: '#0b2241', fontSize: '12px', textAlign: 'right',  textDecoration: 'underline' }}>
              Already have an account? Log in
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;