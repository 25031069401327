import '../styles/App.css';
import { React } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useLoader, getMapImport, getChartImport, getFilterImport } from '../resources/utils';
import { ReactiveOpenStreetMap } from '@appbaseio/reactivemaps';

const mapStyle = {
  display: 'block',
  paddingTop: '40px',
  width: '95%',
  margin: '0 auto',
};

const renderTags = (entityName, items) => {
  if (items && items.length > 0) {
    const itemElements = items.map((item, index) => (
      <span key={index} className="tag">
        {item}
      </span>
    ));

    return (
      <div>
        <strong>{entityName}: </strong>{itemElements.reduce((prev, current) => [prev, ', ', current])}
        <br />
      </div>
    );
  }
  return null; // Return null if there are no items
}

function Map(props) {
  const showLoader = useLoader();
  const useCaseName = props.useCaseKey;
  const filterData = getFilterImport(useCaseName);
  const chartData = getChartImport(useCaseName);
  const filterComponentIds = filterData.map(item => item.componentId);
  const chartComponentIds = chartData.map(item => item.componentId);
  const reactions = ['Search', ...filterComponentIds, ...chartComponentIds];
  const mapData = getMapImport(useCaseName);

  return (
    showLoader ? (
      // Show the loader while showLoader is true
      <div class="loader">
        <LoadingOutlined style={{ color: '#0b2241', fontSize: 50 }} />
      </div>
    ) : (
    <div style={mapStyle}>
        <ReactiveOpenStreetMap
            style={{ height: "65vh" }}
            componentId="map"
            dataField="geoPoint"
            showSearchAsMove ={true}
	          searchAsMove ={true}
            size= {50}
            autoCenter= {true}
            defaultZoom= {2}
            react={{
                and: reactions,
              }}
            onPopoverClick={(item) => (
                <div>
                  {mapData.map((mapItem, index) => (
                    <div key={index}>
                      {renderTags(mapItem.name, item[mapItem.dataField])}
                    </div>
                  ))}
                </div>
              )}
        />
    </div>
  )
  );
}

export default Map;
