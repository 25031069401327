import { React } from 'react';
import { SearchBox } from '@appbaseio/reactivesearch';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { Row, Col, Layout } from 'antd';
import studies from '../resources/studies.json';
import DataCards from './DataCards';
import Navbar from '../main/Navbar';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { clientUrl, defaultIndex, credentials, mapKey, useLoader } from '../resources/utils';

const { Content } = Layout;

const HomeTab = () => {

  const showLoader = useLoader();
  const navigate = useNavigate();

  const handleSearch = (query) => {
    if (query) {
      navigate(`/dashboard/${defaultIndex}`);
    }
  };

  return (
    <>
      <Navbar />
      <Content style={{ padding: '0 50px', background: '#f5f5f5' }}>
      {showLoader ? (
        <div class="loader">
          <LoadingOutlined style={{ color: '#0b2241', fontSize: 50 }} />
        </div>
      ) : (
        <>
        <ReactiveBase
          url={clientUrl}
          app={defaultIndex}
          credentials={credentials}
          mapKey={mapKey}
        >
          <div
            style={{
              textAlign: 'center',
              margin: '50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100vh',
            }}
          >
            <div className="logo">
              <img
                src="./xplorer.png"
                alt="Logo"
                style={{ maxWidth: '250px', maxHeight: '150px', height: 'auto', width: 'auto', paddingTop: '30px' }}
              />
            </div>
            <p>
              Empower Your Search with AI. Explore more than <strong>600 Million</strong> Scientific Documents.
            </p>
            <div className="row">
              <div className="col">
                <SearchBox
                  componentId="searchbox"
                  placeholder="Search by condition type, drug name, or trial name..."
                  style={{ width: 700, paddingTop: '40px', marginLeft: 'auto' }}
                  autosuggest={true}
                  debounce={100}
                  showVoiceSearch={true}
                  dataField={[
                    {
                      field: 'officialTitle',
                      weight: 3,
                  },
                  {
                      field: 'officialTitle.autosuggest',
                      weight: 1,
                  },
                  {
                      field: 'description',
                      weight: 3,
                  },
                  {
                      field: 'description.autosuggest',
                      weight: 1,
                  },
                  {
                      field: 'docId',
                      weight: 3,
                  },
                  {
                      field: 'docId.autosuggest',
                      weight: 1,
                  },
                  ]}
                  highlight
                  URLParams
                  onValueSelected={(value) => handleSearch(value)}
                />
              </div>
            </div>
            <div style={{ paddingTop: '60px' }}>
              <Row gutter={18} align="middle" justify="center">
                {studies.map((item) => (
                  <Col key={item.key}>
                    <DataCards
                      study={item.study}
                      documents={item.documents}
                      imgUrl={item.imgUrl}
                      index={item.index}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </ReactiveBase>
        </>
      )}
      </Content>
    </>
  );
};

export default HomeTab;
