// DashboardTab.js
import React from 'react';

import Analysis from './Analysis';
import Navbar from '../main/Navbar';
import { Tabs, Layout } from 'antd';
import { useParams } from 'react-router-dom';
import Studies from '../resources/studies.json';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactiveBase } from '@appbaseio/reactivesearch';


import { clientUrl, defaultIndex, credentials, mapKey, useLoader } from '../resources/utils';

const { Content } = Layout;
const { TabPane } = Tabs;

function DashboardTab() {
  const showLoader = useLoader();
  //const useCaseName = defaultIndex;
  const { useCaseName } = useParams();

  const index = useCaseName || defaultIndex;

  const tabName = Studies.find(item => useCaseName === item.index);
  
  return (
    <>
      <Navbar />
      <Content style={{ padding: '0 50px', background: '#f5f5f5' }}>
        {showLoader ? (
          <div className="loader">
            <LoadingOutlined style={{ color: '#0b2241', fontSize: 50 }} />
          </div>
        ) : (
          <>
            <ReactiveBase
              url={clientUrl}
              app={index}
              credentials={credentials}
              mapKey={mapKey}
            >
              <div style={{ borderBottom: '0px', minHeight: '100vh' }}>
                <div style={{ paddingTop: '20px' }}>
                  <Tabs style={{ paddingLeft: '10px' }} activeKey={useCaseName}>
                    <TabPane tab={tabName.study} key={useCaseName}>
                      <Analysis useCaseKey={useCaseName} />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </ReactiveBase>
          </>
        )}
      </Content>
    </>
  );
}

export default DashboardTab;
