// Navbar.js
import React from 'react';
import '../styles/App.css';
import logo from '../resources/logo.png';
import { defaultIndex } from '../resources/utils';
import { Layout, Menu, Button, Tooltip, Dropdown } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  BellOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  HomeOutlined,
  BarChartOutlined,
  DatabaseOutlined,
  ContainerOutlined,
  NodeIndexOutlined,
  ReadOutlined,
  SlackOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  RocketOutlined
} from '@ant-design/icons';

const { Header } = Layout;

const settingsIconStyle = {
  border: 'none',
  background: 'transparent',
  boxShadow: 'none',
  marginRight: '10px',
};

const colorBgContainer = "#f5f5f5";

function Logo() {

  return (
    <div className="logo">
      <Link to="/home">
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: '65px', maxHeight: '65px', height: 'auto', width: 'auto', paddingTop: '30px' }}
        />
      </Link>
    </div>
  );
}

function MenuItems() {
  const location = useLocation();

  const menuItems = [
    { key: "1", label: "Home", icon: <HomeOutlined /> },
    { key: "2", label: "Dashboard", icon: <BarChartOutlined /> },
    { key: "3", label: "Data Sources", icon: <DatabaseOutlined /> },
    { key: "4", label: "Collection", icon: <ContainerOutlined /> },
  ];

  // Check if you want to disable the "Collection" tab (for example, based on some condition)
  const isCollectionTabDisabled = true; // Set this based on your logic

  const selectedKey = menuItems.find(item => {
    if (item.label === "Dashboard") {
      return location.pathname === `/dashboard/${defaultIndex}`;
    } else if (item.label === "Home") {
      return location.pathname === "/";
    } else if (item.label === "Data Sources") {
      return location.pathname === "/datasources";
    } else {
      return false;
    }
  })?.key || "2";
  

  return (
    <div className="menu-items" style={{ marginLeft: 'auto' }}>
      <Menu
        style={{ backgroundColor: colorBgContainer, border: 'none' }}
        mode="horizontal"
        theme="light"
        selectedKeys={[selectedKey]}
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.label === "Collection" && isCollectionTabDisabled ? (
              <span>{item.label}</span>
            ) : item.label === "Dashboard" ? (
              <Link to={`/dashboard/${defaultIndex}`}>{item.label}</Link>
            ) : item.label === "Home" ? (
              <Link to={`/`}>{item.label}</Link>
            ) : item.label === "Data Sources" ? (
              <Link to={`/datasources`}>{item.label}</Link>
            ) : null}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
  
}


function ProfileDropdownMenu() {
  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    if (e.key === 'tour') {
        navigate('/tour');
    } else if (e.key === 'docs') {
      
      window.open('https://docs.xplorer.38labs.com/', '_blank');
    } else if (e.key === 'slackSupport') {
      
      window.open('https://xplorer-38labs.slack.com', '_blank');
    }
    
  };

  const menu = (
    <Menu onClick={handleMenuClick} style={{ background: '#f5f5f5', borderRadius: '4px', paddingBottom: '8px', paddingRight: '8px' }}>
      <Menu.Item key="tour">
        <NodeIndexOutlined /> <span className="menu-text">Guided Tour</span>
      </Menu.Item>
      <Menu.Item key="docs">
        <ReadOutlined /> <span className="menu-text">Documentation</span>
      </Menu.Item>
      <Menu.Item key="slackSupport">
        <SlackOutlined /> <span className="menu-text">Online Support</span>
      </Menu.Item>
      <Menu.Item key="whatsnew">
        <RocketOutlined /> <span className="menu-text">What's New</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button icon={<QuestionCircleOutlined />} style={settingsIconStyle} />
    </Dropdown>
  );
}

function SettingsDropdownMenu() {
  const navigate = useNavigate(); 
  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      navigate('/logout');
    }
  };


  const menu = (
    <Menu onClick={handleMenuClick} style={{ background: '#f5f5f5', borderRadius: '4px', paddingBottom: '8px', paddingRight: '8px' }}>
      <Menu.Item key="name">
        <UserOutlined /> <span className="menu-text">Hello !</span>
      </Menu.Item>
      <Menu.Item key="settings">
        <SettingOutlined /> <span className="menu-text">Account Settings</span>
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined /> <span className="menu-text">Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button icon={<MenuOutlined />} style={settingsIconStyle} />
    </Dropdown>
  );
}

function SettingsItems() {
  return (
    <div className="settings-items" style={{ marginLeft: 'auto' }} >
      <Tooltip title="Notifications">
        <Button icon={<BellOutlined />} style={settingsIconStyle} />
      </Tooltip>
      <ProfileDropdownMenu />
      <SettingsDropdownMenu />
    </div>
  );
}

function Navbar() {
  return (
    <Header 
    style={{ 
      display: 'flex', 
      alignItems: 'center', 
      backgroundColor: colorBgContainer, 
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
      borderBottom: '1px solid #ccc' }}>
      <Logo />
      <MenuItems />
      <SettingsItems />
    </Header>
  );
}

export default Navbar;