import '../styles/App.css'
import { List, Space } from 'antd';
import { Link } from 'react-router-dom';
import { React, useState } from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch';
import { getDisplayFunctions, displayContent, useLoader, getFilterImport, getChartImport } from '../resources/utils';
import { CaretDownFilled, CaretUpFilled, LoadingOutlined } from '@ant-design/icons';


function DocList(props) {
  const showLoader = useLoader();
  const useCaseName = props.useCaseKey;
  const filterData = getFilterImport(useCaseName);
  const chartData = getChartImport(useCaseName);
  const filterComponentIds = filterData.map(item => item.componentId);
  const chartComponentIds = chartData.map(item => item.componentId);
  const reactions = ['Search', ...filterComponentIds, ...chartComponentIds];
  
  const DisplayFunctions = getDisplayFunctions(useCaseName);
  const [openMoreInfStates, setOpenMoreInfStates] = useState([]);

  const toggleMoreInfOpen = (index) => {
    const newOpenMoreInfStates = [...openMoreInfStates];
    newOpenMoreInfStates[index] = !newOpenMoreInfStates[index];
    setOpenMoreInfStates(newOpenMoreInfStates);
  };

  return (
    showLoader ? (
      <div class="loader">
        <LoadingOutlined style={{ color: '#0b2241', fontSize: 50 }} />
      </div>
    ) : (
      <div>
      <ReactiveList
        componentId="results"
        dataField="_score"
        size={6}
        pagination={true}
        react={{
          and: reactions,
        }}
        render={({ data }) => (
          <List
          dataSource={data}
          renderItem={(item, index) => {
            return (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Link
                    to={`/${useCaseName}/${item.docId}`}
                    target="_blank" 
                    rel="noopener noreferrer" 
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />}
                  description={
                    <Space direction="vertical">
                      <div style={{paddingRight:'30px'}}>
                      {displayContent(item.description, 'Brief Description: ')}

                      </div>
                      <div onClick={() => toggleMoreInfOpen(index)} className={`moreInfo-title`} style={{ cursor: 'pointer', color: '#1677ff' }}>
                        Xplore more 
                        {openMoreInfStates[index] ? (
                          <CaretUpFilled style={{ fontSize: '14px', paddingLeft: '5px' }} />
                        ) : (
                          <CaretDownFilled style={{ fontSize: '14px', paddingLeft: '5px' }} />
                        )}
                      </div>
                      <div className={`${openMoreInfStates[index] ? 'expanded' : 'collapsed'}`}>
                        {openMoreInfStates[index] && (
                          <DisplayFunctions
                        
                          item={item}
                          page="DocList"
                          />
                        )}
                      </div>
            
                    </Space>
                  }
                />
              </List.Item>
            );
          }}
          />
        )}
      />
      </div>
  )
);
}

export default DocList;
