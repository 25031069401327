import Map from './Map'
import Network from './Network'
import DocList from './DocList'
import SearchBar from './SearchBar'
import Analytics from './Analytics'
import React, { useState } from 'react'
import SidebarFilters from './SidebarFilters'
import {okosUIURL} from '../resources/utils'
import { Button, Tooltip, FloatButton } from 'antd'
import { SelectedFilters } from '@appbaseio/reactivesearch'
import { OrderedListOutlined, PieChartOutlined, ShareAltOutlined, EnvironmentOutlined,  RobotOutlined } from '@ant-design/icons';

const settingsIconStyle = {
  background: 'transparent',
  border: 'none',
  boxShadow: 'none'
};

const Analysis = (props) => {
  const useCaseName = props.useCaseKey;
  const [showList, setShowList] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [showNetwork, setShowNetwork] = useState(false);
  const [showOkos, setShowOkos] = useState(false);

  const handleClick = (listValue, analyticsValue, mapValue, networkValue) => {
    setShowList(listValue);
    setShowAnalytics(analyticsValue);
    setShowMap(mapValue);
    setShowNetwork(networkValue);
  };  
  
  const toggleshowOkos = () => {
    setShowOkos(prevState => !prevState); // Toggle showOkos state
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginTop: '10px', paddingRight: '30px'}}>
          <SidebarFilters useCaseKey={useCaseName}/>
        </div>
        
        <div style={{ display: 'flex', flexDirection: 'column', width: '75%', paddingLeft: '30px' }}>  
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <SearchBar />
            </div>
            
            <div>
              <Tooltip title="List View">
                <Button 
                  onClick={() => handleClick(true, false, false, false)}
                  icon={<OrderedListOutlined style={{ color: showList ? '#1677ff' : 'black'}}/>} 
                  style={settingsIconStyle}
                />
              </Tooltip>
              <Tooltip title="Analytics View">
                <Button onClick={() => handleClick(false, true, false, false)}
                icon={<PieChartOutlined style={{ color: showAnalytics ? '#1677ff' : 'black'}}/>} 
                style={settingsIconStyle}/>
              </Tooltip>
              <Tooltip title="Map View">
                <Button onClick={() => handleClick(false, false, true, false)}
                icon={<EnvironmentOutlined style={{ color: showMap ? '#1677ff' : 'black'}}/>} 
                style={settingsIconStyle}/>
              </Tooltip>
              <Tooltip title="Network View">
                <Button onClick={() => handleClick(false, false, false, true)}
                icon={<ShareAltOutlined style={{ color: showNetwork ? '#1677ff' : 'black'}}/>} 
                style={settingsIconStyle} />
              </Tooltip>
            </div>
          </div>
          
          <div style={{paddingTop:'10px'}}>
            <SelectedFilters />
            {showList ? (
              <DocList useCaseKey={useCaseName}/>
            ) : showAnalytics ? (
              <Analytics useCaseKey={useCaseName}/>
            ) : showMap ? (
              <Map useCaseKey={useCaseName}/>
            ) : showNetwork ? (
              <Network useCaseKey={useCaseName}/>
            ) : null}
          </div>
        </div>
      </div>

      {showOkos && (
      <>
        <iframe 
          src={`${okosUIURL}/${useCaseName}`}
          width="80%" 
          height="80%" 
          style={{ 
            position: 'fixed', 
            bottom: '10%',
            right: '10%',
            zIndex: 9999, 
            border: 'none' 
          }}
          title="Okos Application"
        ></iframe>
      </>
      )}
      
      <FloatButton 
        icon={<RobotOutlined />} 
        onClick={toggleshowOkos}
        style={{ bottom: 40, right: 24 }} 
        tooltip={<div>Open Okos</div>} 
      />

    </div>
  );
};

export default Analysis;