import '../styles/App.css';
import '../styles/index.css';
import { Modal, FloatButton, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { getFilterImport, getChartImport } from '../resources/utils';
import { MultiList, DateRange  } from '@appbaseio/reactivesearch';
import { FilterOutlined, FilterFilled, CaretDownFilled, CaretUpFilled } from '@ant-design/icons';

function SidebarFilters(props) {
  const useCaseName = props.useCaseKey;
  const filterData = getFilterImport(useCaseName);
  const chartData = getChartImport(useCaseName);
  const filterComponentIds = filterData.map(item => item.componentId);
  const chartComponentIds = chartData.map(item => item.componentId);
  const reactions = ['Search', ...filterComponentIds, ...chartComponentIds];


  const toggleAllFilters = (selectAll) => {
    let newSelectedFilters;
    if (selectAll) {
      newSelectedFilters = filterData.map((filter) => filter.name);
    } else {
      newSelectedFilters = [];
    }
  
    // Save the updated selected filters to localStorage
    localStorage.setItem('selectedFilters', JSON.stringify(newSelectedFilters));
  
    setSelectedFilters(newSelectedFilters);
  };

  const [filterStates, setFilterStates] = useState(
    filterData.reduce((acc, filter) => {
      acc[filter.name + 'Visible'] = false;
      return acc;
    }, {})
  );


  const [selectedFilters, setSelectedFilters] = useState([]);


  // Function to add a filter to the selectedFilters state
  const addFilter = (filter) => {
    const updatedFilters = [...selectedFilters, filter];
  
    // Save the updated selected filters to localStorage
    localStorage.setItem('selectedFilters', JSON.stringify(updatedFilters));
  
    setSelectedFilters(updatedFilters);
  };

  // Function to remove a filter from the selectedFilters state
  const removeFilter = (filter) => {
    const updatedFilters = selectedFilters.filter((selectedFilter) => selectedFilter !== filter);
  
    // Save the updated selected filters to localStorage
    localStorage.setItem('selectedFilters', JSON.stringify(updatedFilters));
  
    setSelectedFilters(updatedFilters);
  };

  const toggleFilter = (filterName) => {
    setFilterStates((prevStates) => ({
      ...prevStates,
      [filterName]: !prevStates[filterName],
    }));
  };

  const renderSelectedFilters = () => {
    // Create an object to group filters by theme
    const filtersByTheme = {};
  
    // Group selected filters by theme
    selectedFilters.forEach((selectedFilter) => {
      const filter = filterData.find((filter) => filter.name === selectedFilter);
      if (filter) {
        const theme = filter.theme || 'Uncategorized';
        if (!filtersByTheme[theme]) {
          filtersByTheme[theme] = [];
        }
        filtersByTheme[theme].push(filter);
      }
    });
  
    // Render filters under themes
    return Object.entries(filtersByTheme).map(([theme, filters]) => {
      return (
        <div key={theme} className="theme-container">

          <div className="filtertheme-title" style={{ overflow: 'hidden' }}>
            <h4 style={{display: 'inline-block' }}>{theme}</h4>
            <h4 style={{ display: 'inline-block', float: 'right', fontSize: '15px' }}>{<FilterFilled />}</h4>
          </div>


          {filters.map((filter) => {
            return renderFilter(
              filter.name,
              filter.type,
              filter.componentId,
              filter.dataField,
              filter.showSearch,
              filter.showLoadMore
            );
          })}
        </div>
      );
    });
  };


  const renderFilter = (filterName, type, componentId, dataField, showSearch = true, showLoadMore = false) => {
    const isVisible = filterStates[filterName];
  
    return (
      <div className="filter-container" key={filterName} style={{ cursor: 'pointer' }}>
        <div onClick={() => toggleFilter(filterName)} className="filter-title">
          <h3 style={{ fontSize: '14px' }}>{filterName}</h3>
          <h3 style={{ fontSize: '14px' }}>{isVisible ? <CaretUpFilled /> : <CaretDownFilled />}</h3>
        </div>
        <div className={`filter-content ${isVisible ? 'expanded' : 'collapsed'}`}>
        {isVisible && (
        <>
          {type === 'multilist' && (
            <MultiList
              componentId={componentId}
              dataField={dataField}
              showSearch={showSearch}
              showLoadMore={showLoadMore}
              size={10}
              sortBy='count'
              react={{
                and: reactions,
              }}
            />
          )}
          {type === 'daterange' && (
            <DateRange
              componentId={componentId}
              dataField={dataField}
              react={{
                and: reactions,
              }}
               
            />
          )}
        </>
        )}
        </div>
      </div>
    );
  };
  

  // State to control the visibility of the "Add Filters" modal
  const [addFiltersModalVisible, setAddFiltersModalVisible] = useState(false);

  // Function to open the "Add Filters" modal
  const openAddFiltersModal = () => {
    setAddFiltersModalVisible(true);
  };

  // Function to close the "Add Filters" modal
  const closeAddFiltersModal = () => {
    setAddFiltersModalVisible(false);
  };

  // Set all filters as selected or unselected by default when the component first loads
  useEffect(() => {
    const defaultSelectedFilters = [];

    // Ensure filterData is defined before using it
    if (filterData) {
      // Load the previously selected filters from localStorage
      const storedSelectedFilters = localStorage.getItem('selectedFilters');
      if (storedSelectedFilters) {
        setSelectedFilters(JSON.parse(storedSelectedFilters));
        return;
      }

      // Create an object to group filters by theme
      const filtersByTheme = {};
      // Group filters by theme
      filterData.forEach((filter) => {
        const theme = filter.theme || 'Uncategorized';
        if (!filtersByTheme[theme]) {
          filtersByTheme[theme] = [];
        }
        filtersByTheme[theme].push(filter);
      });
      // Iterate through filtersByTheme and select the first 3 filters from each theme
      Object.entries(filtersByTheme).forEach(([theme, filters]) => {
        if (filters.length > 0) {
          const selectedThemeFilters = filters.slice(0, 4).map((filter) => filter.name);
          defaultSelectedFilters.push(...selectedThemeFilters);
        }
      });
      // Set the default selected filters
      setSelectedFilters(defaultSelectedFilters);
      // Save the default selected filters to localStorage
      localStorage.setItem('selectedFilters', JSON.stringify(defaultSelectedFilters));
    }
  }, [filterData]);


  // Function to organize filters by theme within the modal
  const groupFiltersByTheme = () => {
    const filtersByTheme = {};

    filterData.forEach((filter) => {
      const theme = filter.theme || 'Uncategorized';
      if (!filtersByTheme[theme]) {
        filtersByTheme[theme] = [];
      }
      filtersByTheme[theme].push(filter);
    });

    return filtersByTheme;
  };

  // Render filters within the modal based on themes
  const renderFiltersInModal = () => {
    const filtersByTheme = groupFiltersByTheme();

    return (
      <div className="add-filters-modal">
        <div className="checkbox-label">
          <label>
            <input
              type="checkbox"
              checked={selectedFilters.length === filterData.length}
              onChange={(e) => toggleAllFilters(e.target.checked)}
            />
            Select All
          </label>
        </div>

        {Object.entries(filtersByTheme).map(([theme, filters]) => (
          <div key={theme} className="theme-container">
            <h3 style={{marginBottom:'0px'}}>{theme}</h3>
            {filters.map((filter) => (
              <div key={filter.name} className="checkbox-label">
                <label>
                  <input
                    type="checkbox"
                    value={filter.name}
                    checked={selectedFilters.includes(filter.name)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addFilter(filter.name);
                      } else {
                        removeFilter(filter.name);
                      }
                    }}
                  />
                  {filter.name}
                </label>
              </div>
            ))}
          </div>
        ))}

        <div style={{ position: 'absolute', bottom: '20px', right: '30px' }}>
          <Button type="default" onClick={closeAddFiltersModal}>
            Apply
          </Button>
        </div>
        
      </div>
    );
  };
  

  return (
    <div className="sidebar-container">
      <FloatButton icon={<FilterOutlined />} onClick={openAddFiltersModal} style={{  bottom: 40, left: 15 }} tooltip={<div>Add/Remove Filters</div>} />
      {/* Selected filters in the sidebar */}
      {renderSelectedFilters()}



      {/* "Add Filters" popup using Ant Design Modal */}
      <Modal
        title="Add or Remove Filters"
        open={addFiltersModalVisible}
        onCancel={closeAddFiltersModal}
        footer={null}
      >
        {renderFiltersInModal()}
      </Modal>
    </div>
  );
}

export default SidebarFilters;