// DisplayFunctions.js

import { Tag } from 'antd';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

function DocDisplay ({ item, similarDocuments, page, useCaseName }) {

  function displayEntities(items, entityName, entityColor = '') {
      if (Array.isArray(items)) {
        return (
          <div>
            {items.length > 0 ? (
              <>
                {entityName}: {items.map((item, index) => (
                  <Tag color={entityColor} key={index}>
                    {item}
                  </Tag>
                ))}
              </>
            ) : null}
          </div>
        );
      } else if (typeof items === 'string' || typeof items === 'number') {
        return (
          <div>
            {entityName}: <Tag color={entityColor}>{items}</Tag>
          </div>
        );
      } else {
        return null;
      }
  }

  return page === "DocDetails" ? (
    <Tabs defaultActiveKey="1" tabPosition="left">
      <TabPane tab="Admission Information" key="1">
        {displayEntities(item.category, 'Category', 'geekblue')}
        {displayEntities(item.admissiondate, 'Admission Date','geekblue')}
      </TabPane>
      <TabPane tab="UMLS Extractions" key="2" >
        <div style={{ display: 'flex' }}>
          <div>
            {displayEntities(item.entities, 'Detected Entities','geekblue')}
            {displayEntities(item.cui, 'Concept Unique Identifier','geekblue')}
            {displayEntities(item.tuis, 'Semantic Type','geekblue')}
            {displayEntities(item.types, 'Semantic Type Description label','orange')}
          </div>
        </div>
      </TabPane>
      <TabPane tab="ICD-10 Codes Extractions" key="3" >
        <div style={{ display: 'flex' }}>
          <div>
            {displayEntities(item.icd10, 'ICD 10 Code','orange')}
          </div>
        </div>
      </TabPane>
      <TabPane tab="SNOMED Codes Extractions" key="4" >
        <div style={{ display: 'flex' }}>
          <div>
            {displayEntities(item.snomed, 'SNOMED Code','magenta')}
          </div>
        </div>
      </TabPane>
    </Tabs>
) : (
    <Tabs defaultActiveKey="1" tabPosition="top" className={"moreinfo-tab"}>
      <TabPane tab="Admission Information" key="1" className={"moreinfo-tabpane"}>
        {displayEntities(item.category, 'Category', 'geekblue')}
        {displayEntities(item.admissiondate, 'Admission Date','geekblue')}
      </TabPane>
      <TabPane tab="UMLS Extractions" key="2" className={"moreinfo-tabpane"} >
        <div style={{ display: 'flex' }}>
          <div>
            {displayEntities(item.entities, 'Detected Entities','geekblue')}
            {displayEntities(item.cui, 'Concept Unique Identifier','geekblue')}
            {displayEntities(item.tuis, 'Semantic Type','geekblue')}
            {displayEntities(item.types, 'Semantic Type Description label','orange')}
          </div>
        </div>
      </TabPane>
      <TabPane tab="ICD 10 Codes Extractions" key="3" className={"moreinfo-tabpane"} >
        <div style={{ display: 'flex' }}>
          <div>
          {displayEntities(item.icd10, 'ICD 10 Code','orange')}
          </div>
        </div>
      </TabPane>
      <TabPane tab="SNOMED Codes Extractions" key="4" className={"moreinfo-tabpane"} >
        <div style={{ display: 'flex' }}>
          <div>
            {displayEntities(item.snomed, 'SNOMED Code','magenta')}
          </div>
        </div>
      </TabPane>
    </Tabs>
);
}

export default DocDisplay