import { React, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { authServerUrl } from '../resources/utils';

const VerificationComponent = () => {
  console.log('Starting VerificationComponent...');
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Starting verification process...');

    fetch(`${authServerUrl}/verifytoken/${token}`)
      .then((response) => {
        console.log('Response from server:', response);
        return response.json();
      })
      .then((data) => {
        console.log('Received data from server:', data);
        if (data.message) {
          // Assuming '/login' is the correct path
          navigate('/login');
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }, [token, navigate]);

  return <></>; // Empty JSX fragment
};

export default VerificationComponent;
