import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthenticationService'; // Import your authentication service

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth(); // Use your authentication context to check if the user is authenticated

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
