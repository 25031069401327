import '../styles/App.css';
import html2canvas from 'html2canvas';
import React, { useState } from 'react';
import { ReactiveChart } from '@appbaseio/reactivesearch';
import { Modal, Checkbox, FloatButton, Tabs, Button } from 'antd';
import { getChartImport, getFilterImport, useLoader } from '../resources/utils';
import { PieChartOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

function Analytics(props) {
  const useCaseName = props.useCaseKey;
  const filterData = getFilterImport(useCaseName);
  const chartData = getChartImport(useCaseName);
  const filterComponentIds = filterData.map(item => item.componentId);
  const chartComponentIds = chartData.map(item => item.componentId);
  const reactions = ['Search', ...filterComponentIds, ...chartComponentIds];
  

  const showLoader = useLoader();
  const initialSelectedCharts = chartData.reduce((acc, chart) => {
    if (!acc[chart.theme]) {
      acc[chart.theme] = [];
    }
    if (acc[chart.theme].length < 5) {
      acc[chart.theme].push(chart.componentId);
    }
    return acc;
  }, {});
  const [selectedCharts, setSelectedCharts] = useState(Object.values(initialSelectedCharts).flat());
  const [addChartsModalVisible, setAddChartsModalVisible] = useState(false);

  const openAddChartsModal = () => {
    setAddChartsModalVisible(true);
  };

  const closeAddChartsModal = () => {
    setAddChartsModalVisible(false);
  };

  const toggleChart = (chartId) => {
    let newSelectedCharts;
    if (selectedCharts.includes(chartId)) {
      newSelectedCharts = selectedCharts.filter((id) => id !== chartId);
    } else {
      newSelectedCharts = [...selectedCharts, chartId];
    }

    // Save the updated selected charts to localStorage
    localStorage.setItem('selectedCharts', JSON.stringify(newSelectedCharts));

    setSelectedCharts(newSelectedCharts);
  };

  const toggleAllCharts = (selectAll) => {
    let newSelectedCharts;
    if (selectAll) {
      newSelectedCharts = chartData.map((chart) => chart.componentId);
    } else {
      newSelectedCharts = [];
    }

    // Save the updated selected charts to localStorage
    localStorage.setItem('selectedCharts', JSON.stringify(newSelectedCharts));

    setSelectedCharts(newSelectedCharts);
  };

  const themes = Array.from(new Set(chartData.map(chart => chart.theme)));
  
  function renderTabs() {
    return themes.map((theme, index) => (
      <TabPane tab={theme} key={index + 1} className="chart-tabpane">
        {/* Filter chart data based on theme and render charts */}
        {chartData
          .filter(chart => selectedCharts.includes(chart.componentId) && chart.theme === theme)
          .map(chart => renderChart(chart.name, chart.componentId, chart.dataField, chart.type))}
      </TabPane>
    ));
  }

  const renderChart = (chartName, componentId, dataField, chartType) => {
    
    const handleDownload = () => {

      const echartsInstance = document.querySelector(`[chart-component-id="${componentId}"] .echarts-for-react`);
      
      if (echartsInstance) {
    
        // Use html2canvas to capture the content of the ECharts instance
          html2canvas(echartsInstance).then((canvas) => {
          
          // Convert the canvas to a data URL
          const dataURL = canvas.toDataURL('image/png');
    
          // Create a download link
          const downloadLink = document.createElement('a');
          downloadLink.href = dataURL;
          downloadLink.download = `chart_${chartName}.png`;
    
          // Trigger a click on the download link to start the download
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }).catch((error) => {
          console.error('Error capturing ECharts content:', error);
        });
      } else {
        console.error('ECharts instance not found.');
      }
    };    
  
    return (
      <div key={componentId} chart-component-id={componentId} style={{ display: 'inline-block', position: 'relative', width: '100%', verticalAlign: 'top' }}>
        {chartType === 'histogram' ? (
          <ReactiveChart
            componentId={componentId}
            title={chartName}
            chartType={chartType}
            dataField={dataField}
            react={{
              and: reactions,
            }}
            type="range"
            range={{
              start: 1990,
              end: 2050,
            }}
          />
        ) : (
          <>
            <ReactiveChart
              componentId={componentId}
              chartType={chartType}
              dataField={dataField}
              title={chartName}
              sortBy="count"
              URLParams
              react={{
                and: reactions,
              }}
            />
            {/* Add DownloadOutlined icon */}
            <DownloadOutlined
              style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer', fontSize: '18px' }}
              onClick={handleDownload}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      {showLoader ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <LoadingOutlined style={{ color: '#0b2241', fontSize: 50 }} />
        </div>
      ) : (
        <div style={{ paddingTop: '30px' }}>
          <Tabs defaultActiveKey="1" tabPosition="top" className="chart-tab">
            {renderTabs()}
          </Tabs>
          <FloatButton icon={<PieChartOutlined />} onClick={openAddChartsModal} style={{ bottom: 90,  right: 24 }} tooltip={<div>Add/Remove Charts</div>} />
          <Modal title="Add or Remove Charts" visible={addChartsModalVisible} onCancel={closeAddChartsModal} footer={null}>
            <div className="add-charts-modal">
              <div className="checkbox-label">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedCharts.length === chartData.length}
                    onChange={(e) => toggleAllCharts(e.target.checked)}
                  />
                  Select All
                </label>
              </div>
              {Object.entries(
                chartData.reduce((acc, chart) => {
                  if (!acc[chart.theme]) {
                    acc[chart.theme] = [];
                  }
                  acc[chart.theme].push(chart);
                  return acc;
                }, {})
              ).map(([theme, themeFilters]) => (
                <div key={theme}>
                  <h3 style={{ marginBottom: '0px' }}>{theme}</h3>
                  {themeFilters.map((chart) => (
                    <div key={chart.componentId}>
                      <Checkbox
                        onChange={() => toggleChart(chart.componentId)}
                        checked={selectedCharts.includes(chart.componentId)}
                      >
                        {chart.name}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              ))}
              <div style={{ position: 'absolute', bottom: '20px', right: '30px' }}>
                <Button type="default" onClick={closeAddChartsModal}>
                  Apply
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Analytics;
