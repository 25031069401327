import '../styles/App.css'
import { useParams } from 'react-router-dom';
import { RobotOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { List, Space, Layout, FloatButton } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { clientUrl, okosUIURL, displayContent, useLoader, fetchDataByDoc, fetchSimilarDocuments, getDisplayFunctions } from '../resources/utils';

const { Content } = Layout;

function DocDetails() {

    const { useCaseName, docId } = useParams();
    const defaultIndex = useCaseName;
    const [studyData, setStudyData] = useState(null);
    const [similarDocuments, setSimilarDocuments] = useState([]);
    const [showOkos, setShowOkos] = useState(false);
    const showLoader = useLoader();

    const DisplayFunctions = getDisplayFunctions(useCaseName);
    
    const loadData = useCallback(async () => {
        const data = await fetchDataByDoc(clientUrl, defaultIndex, docId);
        setStudyData(data);
    }, [defaultIndex, docId]);
    
    useEffect(() => {
        loadData();
    }, [loadData, defaultIndex]);

    useEffect(() => {
        // Fetch similar documents when study data is fetched
        if (studyData) {
            fetchSimilarDocuments(clientUrl, defaultIndex, studyData.hits.hits[0]._id)
                .then(similarDocs => setSimilarDocuments(similarDocs));
        }
    }, [defaultIndex, studyData, docId]);

    const toggleshowOkos = () => {
        setShowOkos(prevState => !prevState); // Toggle showOkos state
    };

    return (
        showLoader ? (
            // Show the loader while showLoader is true
            <div className="loader">
                <LoadingOutlined style={{ color: '#0b2241', fontSize: 50 }} />
            </div>
        ) : (
            <Content style={{ padding: '100px 150px ', background: '#f5f5f5' }}>
                <div style={{ position: 'relative', minHeight: '100vh' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {studyData && studyData.hits && studyData.hits.hits.map(hit => (
                            <List.Item key={hit._id} style={{ listStyleType: 'none' }} >
                                <List.Item.Meta
                                    title={<span dangerouslySetInnerHTML={{ __html: hit._source.title }} style={{ paddingLeft: '25px'}}/>}
                                    description={
                                        <Space direction="vertical">
                                            <div style={{ paddingTop: '30px', paddingLeft: '25px', paddingBottom: '40px' }}>
                                                {displayContent(hit._source.description, 'Brief Description: ')}
                                            </div>
                                            <div >
                                                <DisplayFunctions
                                                    item={hit._source}
                                                    similarDocuments={similarDocuments}
                                                    page="DocDetails"
                                                    useCaseName= {useCaseName}
                                                />                          
                                            </div>
                                        </Space>
                                    }
                                />
                            </List.Item>
                        ))}
                    </div>
                    
                    {showOkos && (
                        <iframe 
                            src={`${okosUIURL}/${useCaseName}`}
                            width="80%" 
                            height="80%" 
                            style={{ 
                                position: 'fixed', 
                                bottom: '10%', 
                                right: '10%', 
                                zIndex: 9999, 
                                border: 'none' 
                            }}
                            title="Okos Application"
                        ></iframe>
                    )}

                    <FloatButton 
                        icon={<RobotOutlined />} 
                        onClick={toggleshowOkos} 
                        style={{ bottom: 40, right: 24 }} 
                        tooltip={<div>Open Okos</div>} 
                    />
                </div>
            </Content>
        )
    );
}

export default DocDetails;
