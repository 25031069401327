import React, { useState, useEffect } from 'react';
import { Layout } from 'antd'; 
const { Content } = Layout;

const TextAnimation = () => {
  const text1 = "Welcome to Xplorer.";
  const text2 = "Empower Your Search with AI.";

  const [displayText1, setDisplayText1] = useState('');
  const [displayText2, setDisplayText2] = useState('');

  useEffect(() => {
    let currentIndex1 = 0;
    let currentIndex2 = 0;
    let pauseBetweenText = false;
  
    const animationInterval = setInterval(() => {
      if (currentIndex1 < text1.length) {
        setDisplayText1(text1.slice(0, currentIndex1 + 1));
        currentIndex1++;
      } else if (!pauseBetweenText) {
        // Pause for 2000 milliseconds (2 seconds) between text1 and text2
        pauseBetweenText = true;
        setTimeout(() => {
          // After the pause, continue with text2
          pauseBetweenText = false;
        }, 2000);
      } else if (currentIndex2 < text2.length) {
        setDisplayText2(text2.slice(0, currentIndex2 + 1));
        currentIndex2++;
      } else {
        clearInterval(animationInterval);
      }
    }, 100);
  
    return () => {
      clearInterval(animationInterval);
    };
  }, []);
  
  

  return (
    <Content style={{ padding: '0 50px', background: '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div className="sign-in-container">
        <p style={{ fontSize: '25px', color: '#0b2241', margin: '0 auto', textAlign: 'center' }}>
          {displayText1}<br />{displayText2}
        </p>
      </div>
    </Content>
  );
};

export default TextAnimation;
