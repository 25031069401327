import { useState, useEffect } from 'react';

const config = require('../config');

export const authServerUrl = config.authServerUrl;
export const clientUrl = config.clientUrl;
export const appUrl = config.appUrl;

export const clinicalTrialsUrl = config.clinicalTrialsUrl;
export const okosUIURL = config.okosUIURL;

export const defaultIndex = config.defaultIndex;
export const credentials = config.credentials;
export const mapKey = config.mapKey;

export function getChartImport(useCaseName) {
    let chartData;
    if (useCaseName === 'clinicalstudy') {
      chartData = require('../resources/clinicalstudy/ChartData').default;
    } else if (useCaseName === 'ehr') {
      chartData = require('../resources/ehr/ChartData').default;
    } else {
      chartData = require('../resources/clinicalstudy/ChartData').default;
    }
    return chartData;
  }

export function getFilterImport(useCaseName) {
  let filterData;
  if (useCaseName === 'clinicalstudy') {
    filterData = require('../resources/clinicalstudy/FilterData').default;
  } else if (useCaseName === 'ehr') {
    filterData = require('../resources/ehr/FilterData').default;
  } else {
    // Default import path if no match is found
    filterData = require('../resources/clinicalstudy/FilterData').default;
  }
  return filterData;
}

export function getMapImport(useCaseName) {
  let mapData;
  if (useCaseName === 'clinicalstudy') {
    mapData = require('../resources/clinicalstudy/MapData').default;
  } else if (useCaseName === 'ehr') {
    mapData = require('../resources/ehr/MapData').default;
  } else {
    // Default import path if no match is found
    mapData = require('../resources/clinicalstudy/MapData').default;
  }
  return mapData;
}

export function getDisplayFunctions(useCaseName) {
    let displayFunctions;
    if (useCaseName === 'clinicalstudy') {
        displayFunctions = require('../resources/clinicalstudy/DocDisplay').default;
    } else if (useCaseName === 'ehr') {
        displayFunctions = require('../resources/ehr/DocDisplay').default;
    } else {
      // Default import path if no match is found
      displayFunctions = require('../resources/clinicalstudy/DocDisplay').default;
    }
    return displayFunctions;
  }

export function displayContent(content, label) {
  if (content) {
    const highlightedContent = { __html: content };
    return (
      <div style={{ display: 'inline-block' }}>
        <span><strong>{label}</strong></span>
        <div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={highlightedContent}></div>
      </div>
    );
  } else {
    return null;
  }
}

export function useLoader(delay = 1500) {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, delay);

    return () => {
      clearTimeout(loaderTimeout);
    };
  }, [delay]);

  return showLoader;
}

export const fetchSimilarDocuments = async (clientUrl, index, _id) => {
  try {
      const requestBody = {         
          "query": {
              "more_like_this": {
                  "fields": ["keywords"],
                  "like": [
                      {
                          "_index": index,
                          "_id": _id
                      }
                  ],
                  "max_query_terms": 10,
                  "min_term_freq": 1
              }
          }    
      };

      const response = await fetch(`${clientUrl}/${index}/_search`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + btoa('rs-admin-user:rs-password')
          },
          body: JSON.stringify(requestBody)
      });
      const data = await response.json();
      return data.hits.hits; // Return similar documents data
  } catch (error) {
      console.error('Error fetching similar documents:', error);
      return []; // Return empty array if an error occurs
  }
};

export const fetchDataByDoc = async (clientUrl, index, docId) => {
  try {
      const response = await fetch(`${clientUrl}/${index}/_search?q=docId:${docId}`, {
          method: 'GET',
          headers: {
              'Authorization': 'Basic ' + btoa('rs-admin-user:rs-password')
          }
      });
      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error fetching data:', error);
      return null;
  }
};

export const checkIndexExists = async (clientUrl, index) => {
  try {
    const response = await fetch(`${clientUrl}/${index}`, {
      method: 'HEAD',
      headers: {
        'Authorization': 'Basic ' + btoa('rs-admin-user:rs-password')
      }
    });
    if (response.status === 200) {
      return true;
    } 
    else {
      return false;
    }
  } catch (error) {
    console.error('Error checking index existence:', error);
    return false;
  }
};
