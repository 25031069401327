const chartData = [
    {
      name: 'Primary Outcomes',
      theme: 'Study Outcomes & Status',
      dataField: 'primaryOutcomes.keyword',
      componentId: 'PrimaryOutcomes_Chart',
      type: 'pie'
    },
    {
      name: 'Secondary Outcomes',
      theme: 'Study Outcomes & Status',
      dataField: 'secondaryOutcomes.keyword',
      componentId: 'SecondaryOutcomes_Chart',
      type: 'pie'
    },
    
    {
      name: 'Overall Status',
      theme: 'Study Outcomes & Status',
      dataField: 'overallStatus',
      componentId: 'Status_Chart',
      type: 'pie'
    },
    
    {
      name: 'Study Type',
      theme: 'Study Outcomes & Status',
      dataField: 'studyType',
      componentId: 'Study_Chart',
      type: 'pie'
    },
    
    {
      name: 'Phases',
      theme: 'Study Outcomes & Status',
      dataField: 'phases',
      componentId: 'Phases_Chart',
      type: 'pie'
    },
    
    {
      name: 'Conditions',
      theme: 'Study Outcomes & Status',
      dataField: 'conditions',
      componentId: 'Conditions_Chart',
      type: 'pie'
    },
    
    {
      name: 'Condition Mesh Terms',
      theme: 'Study Outcomes & Status',
      dataField: 'conditionMesh',
      componentId: 'ConditionMesh_Chart',
      type: 'pie'
    },
    {
      name: 'Interventions Type (e.g. Drug, Device)',
      theme: 'Study Outcomes & Status',
      dataField: 'interventionsType',
      componentId: 'InterventionsType_Chart',
      type: 'pie'
    },
    {
      name: 'Intervention (eg. Drug Name)',
      theme: 'Study Outcomes & Status',
      dataField: 'interventions',
      componentId: 'Interventions_Chart',
      type: 'pie'
    },
    {
      name: 'Intervention MeSH Terms (e.g. Drug Class)',
      theme: 'Study Outcomes & Status',
      dataField: 'interventionMesh',
      componentId: 'InterventionMesh_Chart',
      type: 'pie'
    },
    {
      name: 'Study Start Year',
      theme: 'Study Outcomes & Status',
      dataField: 'studyStartYear',
      componentId: 'studyStartYear_Chart',
      type: 'histogram'
    },
    {
      name: 'Primary Completion Year',
      theme: 'Study Outcomes & Status',
      dataField: 'primaryCompletionYear',
      componentId: 'primaryCompletionYear_Chart',
      type: 'histogram'
    },
    {
      name: 'Study Completion Year',
      theme: 'Study Outcomes & Status',
      dataField: 'studyCompletionYear',
      componentId: 'studyCompletionYear_Chart',
      type: 'histogram'
    },
    {
      name: 'Lead Sponsor',
      theme: 'Study Sponsors and Geography',
      dataField: 'leadSponsor',
      componentId: 'Sponsor_Chart',
      type: 'pie'
    },
    {
      name: 'Collaborators',
      theme: 'Study Sponsors and Geography',
      dataField: 'collaborators',
      componentId: 'Collaborators_Chart',
      type: 'pie'
    },
    {
      name: 'Facilities',
      theme: 'Study Sponsors and Geography',
      dataField: 'facilities',
      componentId: 'Facilities_Chart',
      type: 'pie'
    },
    {
      name: 'Cities',
      theme: 'Study Sponsors and Geography',
      dataField: 'cities',
      componentId: 'Cities_Chart',
      type: 'pie'
    },
    {
      name: 'States',
      theme: 'Study Sponsors and Geography',
      dataField: 'states',
      componentId: 'States_Chart',
      type: 'pie'
    },
    {
      name: 'Countries',
      theme: 'Study Sponsors and Geography',
      dataField: 'countries',
      componentId: 'Countries_Chart',
      type: 'pie'
    },
    
    {
      name: 'Ages Eligible',
      theme: 'Study Design',
      dataField: 'stdAges',
      componentId: 'AgeGroups_Chart',
      type: 'pie'
    },
    {
      name: 'Min. Age Eligible',
      theme: 'Study Design',
      dataField: 'minimumAge',
      componentId: 'MinAge_Chart',
      type: 'pie'
    },
    {
      name: 'Sexes Eligible',
      theme: 'Study Design',
      dataField: 'sex',
      componentId: 'Sex_Chart',
      type: 'pie'
    },
    
    {
      name: 'Study Design Allocation',
      theme: 'Study Design',
      dataField: 'designAllocation',
      componentId: 'Allocation_Chart',
      type: 'pie'
    },
    
    {
      name: 'Study Design Intervention Model',
      theme: 'Study Design',
      dataField: 'designInterventionModel',
      componentId: 'InterventionModel_Chart',
      type: 'pie'
    },
    
    {
      name: 'Study Design Masking',
      theme: 'Study Design',
      dataField: 'designMasking',
      componentId: 'Masking_Chart',
      type: 'pie'
    },
    
    {
      name: 'Study Sampling Method',
      theme: 'Study Design',
      dataField: 'samplingMethod',
      componentId: 'Sampling_Chart',
      type: 'pie'
    },
    
    {
      name: 'Study Design Observation Model',
      theme: 'Study Design',
      dataField: 'designObservationalModel',
      componentId: 'ObservationModel_Chart',
      type: 'pie'
    },
    {
      name: 'Study Design Primary Purpose',
      theme: 'Study Design',
      dataField: 'designPrimaryPurpose',
      componentId: 'PrimaryPurpose_Chart',
      type: 'pie'
    },
    {
      name: 'Results',
      theme: 'Study Design',
      dataField: 'hasResults',
      componentId: 'Results_Chart',
      type: 'pie'
    }
  ];
  
export default chartData;
  