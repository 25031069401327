import '../styles/App.css';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const features = [
  {
    title: 'Effortless Contextual Discovery',
    content: 'Seamlessly explore any topic of interest with our intuitive interface. Gain a comprehensive understanding of your context, rapidly locate your specific topic, and effortlessly navigate through interconnected data points and related data types to gain a holistic view of the context.',
    img: './login_images/welcome.png'
  },
  {
    title: 'Unified Intuitive Insights',
    content: 'Experience the power of a unified search that seamlessly explores hundreds of databases and millions of data points in a single click. Elevate your insights with dynamic dashboards that provide high-level overviews. Our intuituve interface features filters and real-time analytics to provide you with comprehensive results.',
    img: './login_images/data.png'
  },
  {
    title: 'Data Discovery',
    content: 'Simplify the search for valuable insights with our no-code solution. Seamlessly navigate through an expansive universe of data from diverse sources, all vetted for reliability. Dive into an extensive repository of over 600 million data points, ensuring your enterprise stays on the cutting edge of information.',
    img: './login_images/search.png'
  },
  {
    title: 'Unlock Effortless Data Discovery',
    content: 'Things got easier with no-code data discovery. Simple steps to search for data Stay up-to-date with reliable data from several different sources that you can trust. Explore through more than 500 million data points',
    img: './login_images/analytics.png'
  },
];

const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
  <span {...props} >{children}</span>
);

const Slider = () => {
  return (
    <div className="slider" style={{ textAlign: 'center' }}>
      <Carousel 
      autoplay 
      arrows 
      nextArrow={
        <SlickButtonFix>
          <RightOutlined />
        </SlickButtonFix>
      }
      prevArrow={
        <SlickButtonFix>
          <LeftOutlined />
        </SlickButtonFix>
      }
      dots={false}
      >
        {features.map((feature, index) => (
          <div key={index}>
            <h2 style={{ color: '#0b2241' }}>{feature.title}</h2>
            <p style={{ width: '70%', margin: '0 auto', color: '#0b2241' }}>{feature.content}</p>
            <img src={feature.img} alt={`Feature ${index + 1}`} style={{
              maxWidth: '60%',
              height: 'auto',
              display: 'block',
              margin: '0 auto',
              paddingTop: '30px',
              paddingBottom: '50px',
              verticalAlign: 'middle'
            }} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Slider;
