const filterData = [
  
    
    
    {
      name: 'Primary Outcomes',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'primaryOutcomes.keyword',
      componentId: 'PrimaryOutcomes_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Secondary Outcomes',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'secondaryOutcomes.keyword',
      componentId: 'SecondaryOutcomes_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Overall Status',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'overallStatus',
      componentId: 'Status_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Study Type',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'studyType',
      componentId: 'Study_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    
    {
      name: 'Phases',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'phases',
      componentId: 'Phases_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Conditions',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'conditions',
      componentId: 'Conditions_Filter',
      showSearch: true,
      showLoadMore: false,
    },
    {
      name: 'Conditions MeSH Terms',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'conditionMesh',
      componentId: 'ConditionMesh_Filter',
      showSearch: true,
      showLoadMore: false,
    },
    {
      name: 'Intervention Type (e.g. Drug, Device)',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'interventionsType',
      componentId: 'InterventionsType_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Intervention (eg. Drug Name)',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'interventions',
      componentId: 'Interventions_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Intervention MeSH Terms (e.g. Drug Class)',
      type: 'multilist',
      theme: 'Study Outcomes & Status',
      dataField: 'interventionMesh',
      componentId: 'InterventionMesh_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Study Start Date',
      type: 'daterange',
      theme: 'Study Outcomes & Status',
      dataField: 'studyStartDate',
      componentId: 'studyStartDate_Filter',
    },
    {
      name: 'Primary Completion Date',
      type: 'daterange',
      theme: 'Study Outcomes & Status',
      dataField: 'primaryCompletionDate',
      componentId: 'primaryCompletionDate_Filter',
    },
    {
      name: 'Study Completion Date',
      type: 'daterange',
      theme: 'Study Outcomes & Status',
      dataField: 'studyCompletionDate',
      componentId: 'studyCompletionDate_Filter',
    },
    {
      name: 'Lead Sponsor',
      type: 'multilist',
      theme: 'Study Sponsors and Geography',
      dataField: 'leadSponsor',
      componentId: 'Sponsor_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Collaborators',
      type: 'multilist',
      theme: 'Study Sponsors and Geography',
      dataField: 'collaborators',
      componentId: 'Collaborators_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Facilities',
      type: 'multilist',
      theme: 'Study Sponsors and Geography',
      dataField: 'facilities',
      componentId: 'Facilities_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Cities',
      type: 'multilist',
      theme: 'Study Sponsors and Geography',
      dataField: 'cities',
      componentId: 'Cities_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'States',
      type: 'multilist',
      theme: 'Study Sponsors and Geography',
      dataField: 'states',
      componentId: 'States_Filter',
      showSearch: false,
      showLoadMore: false,
    },  
    {
      name: 'Countries',
      type: 'multilist',
      theme: 'Study Sponsors and Geography',
      dataField: 'countries',
      componentId: 'Countries_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Ages Eligible',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'stdAges',
      componentId: 'AgeGroups_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    
    {
      name: 'Min. Age Eligible',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'minimumAge',
      componentId: 'MinAge_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Sexes Eligible',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'sex',
      componentId: 'Sex_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Study Design Allocation',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'designAllocation',
      componentId: 'Allocation_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Study Design Intervention Model',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'designInterventionModel',
      componentId: 'InterventionModel_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Study Design Masking',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'designMasking',
      componentId: 'Masking_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Study Sampling Method',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'samplingMethod',
      componentId: 'Sampling_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Study Design Observation Model',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'designObservationalModel',
      componentId: 'ObservationModel_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Study Design Primary Purpose',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'designPrimaryPurpose',
      componentId: 'PrimaryPurpose_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Results',
      type: 'multilist',
      theme: 'Study Design',
      dataField: 'hasResults',
      componentId: 'Results_Filter',
      showSearch: false,
      showLoadMore: false,
    },
  ];
  
  export default filterData;
  