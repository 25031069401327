// DataSourcesTab.js
import { React } from 'react';
import { Tabs, Layout } from 'antd';
import DataTab from './DataTab'; 
import Navbar from '../main/Navbar';
import { useLoader } from '../resources/utils';
import { LoadingOutlined } from '@ant-design/icons';


const { Content } = Layout;
const { TabPane } = Tabs;

function DataSourcesTab() {
  const showLoader = useLoader();

  return (
    <>
      <Navbar />
      <Content style={{ padding: '0 50px', background: '#f5f5f5' }}>
      {showLoader ? (
        // Show the loader while showLoader is true
        <div class="loader">
          <LoadingOutlined style={{ color: '#0b2241', fontSize: 50 }} />
        </div>
      ) : (
        
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', paddingTop: '54px' }}>
          <div>
            <Tabs defaultActiveKey="data" style={{ paddingLeft: '10px' }}>
              <TabPane tab="Data" key="data">
                <DataTab/>
              </TabPane>
            </Tabs>
          </div>
        </div>
      )}
      </Content>
    </>
  );
}

export default DataSourcesTab;
