const filterData = [
  
    
    
    {
      name: 'Patient Category',
      type: 'multilist',
      theme: 'Admission Information',
      dataField: 'category',
      componentId: 'Category_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Admission Date',
      type: 'daterange',
      theme: 'Admission Information',
      dataField: 'admissiondate',
      componentId: 'Admissiondate_Filter',
    },
    {
      name: 'Detected Entities',
      type: 'multilist',
      theme: 'UMLS Extractions',
      dataField: 'entities',
      componentId: 'Entities_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Concept Unique Identifier',
      type: 'multilist',
      theme: 'UMLS Extractions',
      dataField: 'cui',
      componentId: 'CUI_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Semantic Type',
      type: 'multilist',
      theme: 'UMLS Extractions',
      dataField: 'tuis',
      componentId: 'TUIS_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    
    {
      name: 'Semantic Type Description label',
      type: 'multilist',
      theme: 'UMLS Extractions',
      dataField: 'types',
      componentId: 'Types_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'ICD 10 Code',
      type: 'multilist',
      theme: 'UMLS Extractions',
      dataField: 'icd10',
      componentId: 'Icd10_Filter',
      showSearch: true,
      showLoadMore: false,
    },
    {
      name: 'SNOMED Code',
      type: 'multilist',
      theme: 'UMLS Extractions',
      dataField: 'snomed',
      componentId: 'Snomed_Filter',
      showSearch: true,
      showLoadMore: false,
    },
  ];
  
  export default filterData;
  