const MapData = [
    {
      name: 'Conditions',
      dataField: 'conditions',
    },
    {
      name: 'Interventions',
      dataField: 'interventions',
    },
    {
      name: 'Phase',
      dataField: 'phases',
    },    {
      name: 'Lead Sponsor',
      dataField: 'leadSponsor',
    }
  ];
  
export default MapData;
  