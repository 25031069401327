const chartData = [
    {
      name: 'Patient Category',
      theme: 'Admission Information',
      dataField: 'category',
      componentId: 'Category_Chart',
      type: 'pie'
    },
    {
      name: 'Detected Entities',
      theme: 'UMLS Extractions',
      dataField: 'entities',
      componentId: 'Entities_Chart',
      type: 'pie'
    },    
    {
      name: 'Concept Unique Identifier',
      theme: 'UMLS Extractions',
      dataField: 'cui',
      componentId: 'CUI_Chart',
      type: 'pie'
    },
    
    {
      name: 'Semantic Type',
      theme: 'UMLS Extractions',
      dataField: 'tuis',
      componentId: 'TUIS_Chart',
      type: 'pie'
    },
    
    {
      name: 'Semantic Type Description label',
      theme: 'UMLS Extractions',
      dataField: 'types',
      componentId: 'Types_Chart',
      type: 'pie'
    },
    
    {
      name: 'ICD 10 Code',
      theme: 'UMLS Extractions',
      dataField: 'icd10',
      componentId: 'Icd10_Chart',
      type: 'pie'
    },
    {
      name: 'SNOMED Code',
      theme: 'UMLS Extractions',
      dataField: 'snomed',
      componentId: 'Snomed_Chart',
      type: 'pie'
    }
  ];
  
export default chartData;
  