module.exports = {
  appUrl: 'http://xplorer.38labs.com',
  //appUrl: 'http://localhost',
  
  authServerUrl: 'http://20.199.22.128:4000',
  //authServerUrl: 'http://localhost:4000',
  
  clientUrl: 'http://20.199.22.128:8000',
  //clientUrl: 'http://localhost:8000',

  okosAPI: 'http://20.199.22.128:7000',
  //okosAPI: 'http://localhost:7000',

  okosUIURL: 'http://20.199.22.128:5000',
  //okosUIURL: 'http://localhost:5000',

  okosUIPort: '5000',

  clinicalTrialsUrl: 'https://clinicaltrials.gov',

  cSIndex: 'clinicalstudy',
  defaultIndex:'clinicalstudy',
  credentials: 'rs-admin-user:rs-password',
  mapKey: 'AIzaSyDtpYefX6V-pY8xc3-CHHPO25SeoUhShmI',

  openSearchURL:'http://localhost:9200',
  openAIKey: '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'
};
