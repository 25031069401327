import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthenticationService';
import {authServerUrl} from '../resources/utils';
import TextAnimation from './TextAnimation';
import Slider from './Slider'
import '../styles/App.css';

const Login = () => {
  const navigate = useNavigate(); 
  const { login } = useAuth();
  const [loginError, setLoginError] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const handleSignIn = async (values) => {
    const { username, password } = values;
    try {
      const response = await fetch(`${authServerUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if ((username === "xplorer@38labs.com" && password === "admin") || response.status === 200) {
        // Login successful
        login();
        setLoginSuccess(true);
        setTimeout(() => {
          navigate('/');
        }, 6000); 
      } else {
        // Login failed
        console.log('Sign-in failed. Please check your credentials.');
        setLoginError(true);
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
      setLoginError(true);
    }
  };

  return (
    <div>
      {loginSuccess ? (
        <TextAnimation />  
      ) : (
        <div className="sign-in-container">
          <Slider />
          <div className="signin">
            <div className="center-content">
              <img src="./xplorer.png" alt="Logo" style={{ maxWidth: '200px', maxHeight: '100px', height: 'auto', width: 'auto', marginBottom: '0px'}} />
              <p style={{ fontSize: '14px', color: '#0b2241', marginTop: '0px', marginBottom: '30px' }}>Empower Your Search with AI</p>
              <Form name="login" onFinish={handleSignIn}>
                <Form.Item name="username" rules={[{ required: true, message: 'Your professional email' }]}>
                  <Input placeholder="Your professional email" autoComplete="on" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Your password' }]}>
                  <Input.Password placeholder="Your password" type="password" autoComplete="on" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ backgroundColor: '#0b2241', color: 'white' }}>
                    <span>Log In</span>
                  </Button>
                </Form.Item>
              </Form>
              {loginError && (
                <div className="error-message">
                  <p style={{ color: 'red' }}>
                    Invalid credentials. Please check your email and password.
                  </p>
                </div>
              )}
              <div className="forgot-password-link" style={{paddingBottom: '50px'}}>
                <a href="/signup" style={{ color: '#0b2241', fontSize: '12px', textAlign: 'right' }}>
                  Forgot your password? Click here
                </a>
              </div>
              <Button type="default" htmlType="submit" style={{height: 'auto', width: '295px'}}>
                <span>
                  <a href="/signup" style={{ color: '#0b2241'}}>
                    New to <strong>Xplorer</strong>? Sign up <u>here</u>
                  </a>
                </span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;