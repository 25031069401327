// CollectionTab.js
import React from 'react';
import Navbar from '../main/Navbar';
import { Layout } from 'antd';

const { Content } = Layout;

function CollectionTab() {
  return (
  <>
    <Navbar />
    <Content style={{ padding: '0 50px', background: '#f5f5f5' }}>
      <div>
        <h2>Collection</h2>
        {/* Add your Collection here */}
      </div>
    </Content>
  </>
  );
}

export default CollectionTab;
