import React, { useState } from 'react';
import { Table, Tag } from 'antd';
import dataSource from '../resources/titles.json';

const DataTab = () => {
  const [expandedRowKeys] = useState([]);
  const [pageSize] = useState(12); // Set the number of rows per page here

  const tagColors = {
    D: 'geekblue',
    P: 'magenta',
    C: 'cyan',
    T: 'orange',
    R: 'purple',
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '#Documents',
      dataIndex: 'documents',
      key: 'documents',
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            const firstLetter = tag.charAt(0).toUpperCase();
            const color = tagColors[firstLetter] || 'green';

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <div className={`expanded-content ${expandedRowKeys.includes(record.key) ? 'expanded' : ''}`}>
        <h2>Hello</h2>
        {/* Add your additional details here */}
      </div>
    );
  };

  return (
    <div className="data-sources-container">
      <Table
        columns={columns}
        dataSource={dataSource}
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpand: (expanded, record) => {
            /*
            if (expanded) {
              setExpandedRowKeys([record.key]);
            } else {
              setExpandedRowKeys([]);
            }
            */
          },
        }}
        pagination={{ pageSize }} // Set the number of rows per page
      />
    </div>
  );
};

export default DataTab;
