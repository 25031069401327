import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

function DataCards({ study, documents, imgUrl, index }) {
  return (
    <div>

        <Link to={{ pathname: `/dashboard/${index}`}}>
          <CardWithContent study={study} documents={documents} imgUrl={imgUrl} index={index} />
        </Link>
      
    </div>
  );
}

function CardWithContent({ study, documents, imgUrl }) {
  const cardStyle = {
    width: '140px',
    height: '90px',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #0b2241',
    marginBottom: '20px',
  };

  return (
    <Card
      hoverable={true}
      style={cardStyle}
    >
      <img
        src={imgUrl}
        style={{ width: '20px', height: '20px', objectFit: 'cover', marginTop: '10px' }}
        alt={study}
      />
      <p style={{ fontSize: '12px', color: '#0B2241', margin: '5px 0' }}>{study}</p>
      <p style={{ fontSize: '14px', color: '#6A5ACD', margin: '-3px 0 5px 0' }}>{documents}</p>
    </Card>
  );
}

export default DataCards;
