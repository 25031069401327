import React from 'react';
import '../styles/App.css';
import HomeTab from '../home/HomeTab';
import { Route, Routes } from 'react-router-dom';
import DataSourcesTab from '../data/DataSourcesTab';
import DashboardTab from '../dashboard/DashboardTab';
import CollectionTab from '../collection/CollectionTab';
import Signup from '../authentication/Signup';
import Login from '../authentication/Login';
import Logout from '../authentication/Logout';
import PrivateRoute from '../authentication/PrivateRoute';
import { AuthProvider } from '../authentication/AuthenticationService';
import VerificationComponent from '../authentication/VerificationComponent';
import DocDetails from '../dashboard/DocDetails';
import {Layout} from 'antd'; 

const App = () => {
  return (

    <Layout className="layout">
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PrivateRoute><HomeTab /></PrivateRoute>} />
          <Route path="/home" element={<PrivateRoute><HomeTab /></PrivateRoute>} />
          <Route path="/dashboard" element={<PrivateRoute><DashboardTab /></PrivateRoute>} />
          <Route path="/dashboard/:useCaseName" element={<PrivateRoute><DashboardTab /></PrivateRoute>}/>
          <Route path="/datasources" element={<PrivateRoute><DataSourcesTab /></PrivateRoute>}/>
          <Route path="/collection" element={<PrivateRoute><CollectionTab /></PrivateRoute>}/>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/verify/:token" element={<VerificationComponent />} />
          <Route path="/:useCaseName/:docId" element={<DocDetails />} />
        </Routes>
      </AuthProvider>
    </Layout>
  );
};

export default App;